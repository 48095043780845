$color-main-white: #FFFFFF;
$color-main-pink: #AF2BD0;
$color-background: #0D0A31;
$color-background-authorization: #16133F;
$color-error: #D02B67;

$color-secondary-purple: #9879EF;
$color-secondary-violet: #4E12BA;
$color-secondary-stars: #FFE600;
$color-secondary-violet-btns-hover: #433D6A;
$color-secondary-pink-btns-hover: #D436FC;
$color-secondary-pink-btns-pressed: #821B9B;
$color-secondary-btns-pressed-light: #BB86C9;
$color-secondary-violet-dark: #31295B;

$color-shades-violet-60: rgba(67, 61, 106, 0.6);
$color-shades-violet-30: rgba(67, 61, 106, 0.3);
$color-shades-white-20: rgba(255, 255, 255, 0.2);
$color-shades-white-5: rgba(255, 255, 255, 0.05);

$color-gradient-main: linear-gradient(272.86deg, #5800F8 0.59%, rgba(112, 16, 211, 0.81) 40.46%, #8C23A7 100%);
$color-gradient-btns-pressed: linear-gradient(272.86deg, #5504E7 0.59%, rgba(103, 9, 200, 0.81) 40.46%, #811A9B 100%);
$color-gradient-btns-hover: linear-gradient(272.86deg, #782DFF 0.59%, #8C47D2 49.07%, #D331FC 100%);