h1 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 60px;
  margin: 0; 

  @media (max-width: 1280px) {
    font-size: 40px;
  }

  @media (max-width: 996px) {
    font-size: 32px;
    line-height: 40px;
  }
 
  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 32px;
  }
}

// h2 {
//   font-family: "Clash Display";
//   font-style: normal;
//   font-weight: 600;
//   font-size: 56px;
//   line-height: 64px;
//   margin-bottom: 40px;

//   @media (max-width: 996px) {
//     font-size: 32px;
//     line-height: 40px;
//   }

//   @media (max-width: 576px) {
//     font-size: 20px;
//     line-height: 32px;
//     margin-bottom: 16px;
//   }
// }

h3 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  margin: 0;

  @media (max-width: 1280px) {
    font-size: 40px;
  }

  @media (max-width: 996px) {
    font-size: 30px;
    line-height: 44px;
  }

  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 24px;
  }
}

// h4 {
//   font-family: "Clash Display";
//   font-style: normal;
//   font-weight: 600;
//   font-size: 36px;
//   line-height: 48px;
//   margin: 0;

//   @media (max-width: 576px) {
//     font-size: 20px;
//     line-height: 24px;
//   }
// }

.text-cd-400-40 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 32px;
}

.text-cd-500-28 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
}

.text-cd-400-24 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.text-lexend-300-24 {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
}

.text-lexend-500-24 {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.text-cd-500-20 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.text-cd-500-24 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.text-cd-500-18 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.text-cd-500-14 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.text-lexend-400-20 {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.text-lexend-500-18 {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.text-lexend-400-18 {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.text-lexend-500-16 {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.text-lexend-400-16 {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.text-cd-400-14 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
}

.text-lexend-400-14 {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.title-cd-600-32 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.title-cd-600-24 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.title-cd-600-18 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.title-lexend-700-20 {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}
