@import "../../assets/styles/mixins";

.button {
    @include button();

    .icon-right {
        position: absolute;
        top: 15px;
        left: 180px;

        @media (max-width: 996px) {
            left: 90px;
          }
    }
}
