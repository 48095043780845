@import "../../assets/styles/colors";
@import "../../assets/styles/typography";

.page-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 40px 80px 40px 80px;
  background-color: inherit;
  max-width: 1440px;
  margin: auto;

  @media (max-width: 996px) {
    padding: 40px;
  }

  @media (max-width: 375px) {
    padding: 16px 13px;
  }

  &__nav-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 64px;
    padding-right: 30px;
  }

  &__logo-wrapper {
    margin-right: 8px;
    cursor: pointer;
  }

  &__logo {
    @media (max-width: 375px) {
      width: 83px;
      height: 32px;
    }
  }

  &__nav-item {
    @extend .text-lexend-400-20;
    cursor: pointer;
    white-space: nowrap;

    // &:not(:last-child) {
    //   margin-right: 64px;
    // }

    &.active {
      @extend .title-lexend-700-20;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: 10px;
        height: 10px;
        border-radius: 64px;
        background-color: $color-main-white;
      }
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__search-input {
    padding-left: 56px !important;
  }

  &__profile-container {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 25px;
  }

  &__profile-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 15px;
    cursor: pointer;
    width: 64px;
    height: 64px;
    background: $color-shades-violet-60;
    border-radius: 64px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    @media (max-width:576px) {
      background: inherit;
      justify-content: start;
      width: 100%;
      height: 100%;

      & svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__actions-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    cursor: pointer;
  }

  &__auth-btns-container {
    @extend .text-lexend-400-20;
    display: flex;
  }

  &__sign-in-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 30px;
    white-space: nowrap;
  }

  &__sign-up-btn {
    cursor: pointer;
    padding: 14px 50px;
    border: 2px solid transparent;
    background-image: linear-gradient(#16133f, #16133f), $color-gradient-main;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-radius: 8px;
    white-space: nowrap
  }

  &__popover-wrapper {
    position: absolute;
    width: 210px;
    bottom: -170px;
    right: 0;
    z-index: 1;
  }

  &__popover {
    background: #362a66;
    border: 1px solid rgba(67, 61, 106, 0.6);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  &__popover-item {
    display: flex;
    align-items: center;
    padding: 14px 18px;
    width: 100%;

    & svg {
      margin-right: 8px;
    }
  }

  &__mobile-menu-wrapper {
    position: absolute;
    width: 100%;
    top: 110px;
    right: 0;
    z-index: 1;

    @media (max-width: 576px) {
      top: 90px;
    }

    @media (max-width: 375px) {
      top: 60px;
    }
  }

  &__mobile-menu {
    background: #1B0C4D;
  }

  &__mobile-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    width: 100%;
    cursor: pointer;
  }

  &__light-circle {
    left: -201px;
    top: -110px;
  }
}