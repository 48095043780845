@import "../../assets/styles/colors";

.background-light-circle-wrapper {
  overflow: hidden;
}

.background-effect {
  overflow: hidden;
}

.background-light-circle {
  position: absolute;
  background: $color-secondary-violet;
  filter: blur(243px);
  -webkit-filter: blur(243px);
  -moz-filter: blur(243px);
  -ms-filter: blur(243px);
  width: 530px;
  height: 530px;
  z-index: -1;
  overflow: hidden;

  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  @supports (-webkit-touch-callout: none) {
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    -moz-transform: none;
  }

  @media (max-width: 800px) {
    display: none;
  }

  &.large {
    width: 768px;
    height: 768px;
  }
}

.background-star {
  position: absolute;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: -1;
  overflow: hidden;

  &.ex-large {
    width: 48px;
    height: 48px;
  }

  &.large {
    width: 39px;
    height: 39px;
  }

  &.medium {
    width: 31px;
    height: 31px;
  }

  &.small {
    width: 24px;
    height: 24px;
  }

  &.ex-small {
    width: 15px;
    height: 15px;
  }
}

.ellipse {
  border: 1px solid rgba(151, 120, 238, 0.6);
  border-radius: 50%;
  z-index: -1;
  position: absolute;
  overflow: hidden;

  @media (max-width: 800px) {
    display: none;
  }

  &.large {
    width: 670px;
    height: 670px;
  }

  &.medium {
    width: 460px;
    height: 460px;
  }
}