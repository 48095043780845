@import "./colors";

* {
  box-sizing: border-box;
}

html {
  margin: 0;
  width: 100%;
  min-height: 100vh;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
  font-family: "Lexend";
  color: $color-main-white;
  background-color: $color-background;
}

#root {
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

#overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

ul {
  margin: 0;
  padding: 0;
}

.page-container {
  margin: 80px 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
