@import "./colors";
@import "./fonts";
@import "./typography";

@mixin button {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $color-main-white;
  border: none;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
  white-space: nowrap;

  &.primary {
    @extend .text-cd-500-24;
    padding: 15px 50px;
    border-radius: 10px;
    background: $color-gradient-main;

    @media (max-width: 1310px) {
      font-size: 16px;
      line-height: 24px;
      padding: 12px 0;
      border-radius: 8px;
    }

    &:hover {
      background: $color-secondary-violet;
    }

    &:active {
      background: $color-gradient-btns-pressed;
    }

    &:disabled {
      background: $color-secondary-violet;
      cursor: default;
    }

    &.small {
      @extend .text-cd-500-20;
      padding: 16px 28px;
      border-radius: 12px;

      @media (max-width: 1280px) {
        padding: 16px 16px;
      }
    }

    &.x-small {
      @extend .text-cd-500-18;
      padding: 8px;
    }

    &.violet {
      background: $color-shades-violet-60;
      border: 1px solid #4e12ba;

      &:hover {
        background-color: $color-secondary-violet-btns-hover;
      }

      &:active {
        background: $color-shades-violet-30;
      }
    }

    &.pink {
      background: $color-main-pink;

      &:hover {
        background-color: $color-secondary-pink-btns-hover;
      }

      &:active {
        background: $color-secondary-pink-btns-pressed;
      }
    }
  }

  &.secondary {
    @extend .text-lexend-400-16;
    padding: 7px 0;
    background-color: $color-main-pink;
    border-radius: 4px;

    &:hover {
      background-color: $color-secondary-pink-btns-hover;
    }

    &:active {
      background: $color-secondary-pink-btns-pressed;
    }
  }

  &.outlined {
    @extend .text-lexend-400-16;
    background-color: inherit;
    padding: 0;
    border-radius: 8px;
    border: 1px solid $color-main-white;

    &:hover {
      color: $color-secondary-purple;
      border: 1px solid $color-secondary-purple;
    }

    &:active {
      color: $color-secondary-btns-pressed-light;
      border: 1px solid $color-secondary-btns-pressed-light;
    }
  }

  &.arrow {
    @extend .text-cd-400-40;
    background-color: inherit;
    position: relative;

    @media (max-width: 996px) {
      font-size: 18px;
      line-height: 24px;

      & svg {
        width: 92px;
        height: 39px;
      }
    }


    &:hover {
      color: $color-secondary-purple;

      svg path {
        fill: $color-secondary-purple;
      }

      svg circle {
        stroke: $color-secondary-purple;
      }
    }

    &:active {
      color: $color-secondary-violet;

      svg path {
        fill: $color-secondary-violet;
      }

      svg circle {
        stroke: $color-secondary-violet;
      }
    }
  }

  &.submit {
    @extend .title-cd-600-18;
    background: $color-secondary-violet;
    border-radius: 8px;
    padding: 13px 0;

    &.active {
      background: $color-gradient-btns-pressed;
      cursor: pointer;

      &:hover {
        background: $color-gradient-btns-hover;
      }
    }

    &:disabled {
      cursor: default;
    }
  }

  &.white-border {
    border: 1px solid $color-main-white !important;
  }

  &.purple-border {
    border: 1px solid $color-secondary-purple !important;
  }

  &.flex-grow {
    flex: 1 1;
    padding-left: 0;
    padding-right: 0;
  }

  &.full-width {
    width: 100% !important;
  }

  &.auto-width {
    width: auto !important;
  }

  &.small {
    @extend .text-lexend-400-14;
    padding: 4px 0;
  }

  &.large {
    @extend .text-lexend-400-18;
    padding: 13px 0;
  }
}

@mixin input {
  @extend .text-lexend-400-20;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 14px;
  background-color: $color-shades-violet-60;
  border-radius: 12px;
  border: none;
  color: $color-main-white;

  &::placeholder {
    color: $color-main-white;
  }

  &:focus-visible {
    outline: 0;
  }

  &.with-label {
    @extend .text-cd-500-18;
    border: 1px solid $color-secondary-purple;
    border-radius: 8px;
    background-color: inherit;

    &.label-top {
      background-color: $color-background-authorization;

      &:focus,
      &:not(:focus):valid {
        border: 1px solid transparent;
        background-image: linear-gradient(#16133f, #16133f), $color-gradient-btns-hover;
        background-origin: border-box;
        background-clip: padding-box, border-box;
        border-radius: 8px;
        transition: background-position 0.8s ease-out;
      }
    }

    &.error {
      border: 1px solid $color-error !important;
    }

    &.large {
      padding: 16px;
    }

    &.full-width {
      width: 100%;
    }

    &.action-right {
      padding-right: 50px;
    }
  }
}

@mixin dropdown {
  @extend .text-lexend-500-24;
  background: $color-secondary-violet-dark;
  color: $color-main-white;
  border-radius: 8px;
  border: 0;
  width: 100%;
  resize: none;
  padding: 16px 40px 16px 16px;
  min-width: 250px;
  cursor: pointer;
  caret-color: transparent;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  @media (max-width: 576px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
    padding: 12px 56px 12px 12px;
  }

  &:focus-visible {
    outline: none;
  }

  // &.primary {
  //   background-color: $color-white;
  //   border: 1px solid rgba(99, 111, 126, 0.17);
  // }

  // &.secondary {
  //   background: linear-gradient(350.64deg, rgba(219, 219, 219, 0.7) -189.3%, rgba(219, 219, 219, 0.126) 216.68%);
  //   border: none;
  // }

  // &.default {
  //   background: linear-gradient(1.4deg, #FFFFFF -106.02%, rgba(255, 255, 255, 0) 239.55%);
  //   border: 1px solid rgba(99, 111, 126, 0.17);
  // }
}

@mixin calculate_content-sides {
  padding: 0 80px;
}