@import "./typography";
@import "./colors";

.main-container {
  padding: 0 80px;
  flex: 1;
  margin: auto;
  max-width: 1440px;
  width: 100%;

  @media (max-width: 996px) {
    padding: 0 40px;
  }

  @media (max-width: 375px) {
    padding: 16px;
  }
}

.underline {
  cursor: pointer;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.d-f {
  display: flex;
}

.j-c {
  justify-content: center;
}

.a-c {
  align-items: center;
}

.m-t-24 {
  margin-top: 24px;
}

.semi-bold {
  font-weight: 500;
}

.bold {
  font-weight: 600
}

.helper-text {
  @extend .text-cd-500-14;
  color: $color-error;
}

.otp-input__helper-text {
  @extend .helper-text;
  padding-top: 16px;
}

.c-p {
  cursor: pointer;
}

.link {
  color: #9879EF;
  text-decoration: none;
}

.otp {
  width: 50px;
  height: 62px;
  justify-content: center;
  background: $color-shades-white-5;
  border-radius: 5px;
  border: 1px solid $color-shades-white-5;

  @media (max-width: 576px) {
    width: 45px;
    height: 57px;
  }

  @media (max-width: 375px) {
    width: 40px;
    height: 52px;
  }

  &:has(input:focus),
  &:has(input:not(:focus):valid) {
    border: 1px solid transparent;
    background-image: linear-gradient(#16133f, #16133f), $color-gradient-main;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-radius: 5px;
    transition: background-position 0.8s ease-out;
  }

  & input {
    @extend .text-lexend-400-20;
    background-color: transparent;
    border: none;
    padding: 0;
    color: $color-main-white;
  }

  & input:focus {
    border: none;
    outline: none;
  }
}

.otp-container {
  column-gap: 12px;
  margin-top: 20px;

  @media (max-width: 375px) {
    column-gap: 8px;
  }
}

.connect-modal {
  background-color: #31295b;

  &__btn-wrapper {
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 690px) {
      width: 100%;
      padding-top: 24px;
      h3 {
        font-size: 12px;
      }
      > * {
        margin-bottom: 15px;
      }
    }
  }

  &__btn {
    width: 289px;
    padding: 14px 0 !important;

    @media (max-width: 690px) {
      width: 100%;
    }
  }
}
