@font-face {
  font-family: "Clash Display";
  src: url("../fonts/ClashDisplay-Variable.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Lexend";
  src: url("../fonts/Lexend-VariableFont.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: url("../fonts/Mulish-VariableFont.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "DMSans Regular";
  src: url("../fonts/DMSans-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "DMSans Medium";
  src: url("../fonts/DMSans-Medium.ttf") format("truetype");
  font-display: swap;
}
