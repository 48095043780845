@import "../../assets/styles/typography";
@import "../../assets/styles/colors";

.footer {
  width: 100%;
  padding: 40px 40px 32px 40px;
  background-color: #16133f;

  @media (max-width: 996px) {
    padding: 32px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba($color-secondary-purple, 0.6);
    padding-bottom: 32px;

    @media (max-width: 996px) {
      flex-direction: column;
    }
  }

  &__content-item {
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(:first-child) {
      padding-left: 25px;

      @media (max-width: 996px) {
        padding-left: 0;
      }
    }

    @media (max-width: 996px) {
      flex-direction: column;
    }
  }

  &__logo-wrapper {
    margin-right: 40px;
    cursor: pointer;

    @media (max-width: 996px) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-right: 0;
      padding-bottom: 24px;
    }
  }

  &__nav-item {
    @extend .text-lexend-400-20;
    margin-left: 70px;
    cursor: pointer;
    display: flex;
    justify-content: center;

    @media (max-width: 1200px) {
      margin-left: 50px;
    }

    @media (max-width: 996px) {
      margin: 0;
      color: #9879EF;
      opacity: 0.7;
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 12px;
    }
  }

  &__email {
    font-family: "DMSans Regular";
    font-size: 20px;
    line-height: 28px;
    padding-left: 15px;
    color: #9879EF;
    text-decoration: none;

    @media (max-width: 996px) {
      padding-left: 0;
      color: #9879EF;
      opacity: 0.7;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__subscribe {
    font-family: "DMSans Medium";
    font-size: 20px;
    line-height: 26px;
  }

  &__input-container {
    display: flex;
    padding-top: 20px;
  }

  &__input {
    font-family: "Mulish";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    width: 340px;

    color: #ffffff;
    background-color: inherit;

    border: 1px solid #9474ea;
    border-radius: 2px 0px 0px 2px;
  }

  &__subscribe-btn {
    font-family: "Mulish";
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;

    padding: 0 21px;

    background-color: #9474ea;
    border-radius: 0px 2px 2px 0px;
  }

  &__contact-details {
    @extend .title-lexend-700-20;

    @media (max-width: 996px) {
      width: 100%;
      justify-content: center;
      color: #9879EF;
      opacity: 0.7;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    color: $color-secondary-purple;
    opacity: 0.8;
    

    @media (max-width: 996px) {
      flex-direction: column-reverse;
      padding-top: 16px;
      color: #9879EF;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__terms-wrapper {
    display: flex;
  }

  &__terms {
    padding-left: 24px;
    text-decoration: none;
    color: #9879EF;
    cursor: pointer;
  }

  &__marker {
    @media (max-width: 996px) {
      padding-top: 12px;
    }
  }

  &__social {
    a {
      color: #ffffff;
    }

    span {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  &__partners {
    img {
      height: 40px;
      padding-right: 20px;
    }
  }

  &__contacts {
    text-align: center;
    margin-top: 15px;
  }
}
